import React from 'react';
import styled from 'styled-components';

const experiences = [
  {
    id: 1,
    date: 'Sept 2023 – Present',
    length: 'Current',
    title: 'PhD candidate in Data Visualization',
    institution: 'University of Edinburgh',
    location: 'Edinburgh, UK',
  },
  {
    id: 2,
    date: 'April 2023 – July 2023',
    length: '2 months',
    title: 'Digital Research Ambassador',
    institution: 'Digital Research Services',
    location: 'Edinburgh, UK',
  },
  {
    id: 3,
    date: 'Sept 2022 – Sept 2023',
    length: '1 year',
    title: 'Master of Arts: Design Informatics',
    institution: 'University of Edinburgh',
    location: 'Edinburgh, UK',
  },
];

const Experience = () => {
  return (
    <ExperienceSection>
      <Heading>Experience</Heading>
      {experiences.map(experience => (
        <ExperienceItem key={experience.id}>
          <Date>{experience.date}</Date>
          <Length>{experience.length}</Length>
          <Title>{experience.title}</Title>
          <Institution>{experience.institution}</Institution>
          <Location>{experience.location}</Location>
        </ExperienceItem>
      ))}
    </ExperienceSection>
  );
};

const ExperienceSection = styled.section`
  padding: 20px;
  font-family: 'Arimo', sans-serif;
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
  font-family: 'Arimo', sans-serif;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ExperienceItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #00b8ff;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
`;

const Date = styled.span`
  font-size: 15px;
  color: #333;
  flex: 1;
  font-family: 'Arimo', sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Length = styled.span`
  font-size: 15px;
  color: #333;
  flex: 1;
  font-family: 'Arimo', sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Title = styled.span`
  font-size: 15px;
  color: #333;
  flex: 2;
  font-family: 'Arimo', sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Institution = styled.span`
  font-size: 15px;
  color: #333;
  flex: 2;
  font-family: 'Arimo', sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Location = styled.span`
  font-size: 15px;
  color: #333;
  flex: 1;
  font-family: 'Arimo', sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export default Experience;
