import React from 'react';
import styled from 'styled-components';

const Research1 = () => {
  return (
    <ResearchContainer>
      <ResearchHeader>Research and practice in Data Visualization</ResearchHeader>
      <IntroParagraph>
        This research focuses on the principles and methodologies applied in data visualization, aiming to improve clarity and comprehension.
      </IntroParagraph>

      <EmbeddedVisualization>
        <iframe 
          src="https://flo.uri.sh/story/1874026/embed" 
          className="flourish-embed-iframe" 
          frameBorder="0" 
          scrolling="no" 
          style={{ width: '100%', height: '500px' }}
          title="Embedded Visualization"
        ></iframe>
      </EmbeddedVisualization>

      <Gif src="path/to/your/animation.gif" alt="Research Animation" />

      <ImagesContainer>
        <Image src="./assets/research1_img1.jpg" alt="Research Image 1" />
        <Image src="./assets/research1_img2.jpg" alt="Research Image 2" />
        <Image src="./assets/research1_img3.jpg" alt="Research Image 3" />
        <Image src="./assets/research1_img4.jpg" alt="Research Image 4" />
        <Image src="./assets/research1_img5.jpg" alt="Research Image 5" />
      </ImagesContainer>

      <VideoFrame 
        src="https://player.vimeo.com/video/822681754?h=a01a30709a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
        frameBorder="0" 
        allow="autoplay; picture-in-picture" 
        allowFullScreen
        title="Vimeo Video"
      ></VideoFrame>
    </ResearchContainer>
  );
};

const ResearchContainer = styled.div`
  padding: 20px;
  font-family: 'Arimo', sans-serif;
`;

const ResearchHeader = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
`;

const IntroParagraph = styled.p`
  font-size: 1rem;
  color: #333;
  margin-bottom: 20px;
`;

const EmbeddedVisualization = styled.div`
  margin-bottom: 20px;
  iframe {
    width: 100%;
    height: 500px;
  }
`;

const Gif = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

const Image = styled.img`
  flex: 1 1 calc(33.333% - 10px); /* Adjust this value to control the number of images per row */
  max-width: calc(33.333% - 10px);
  height: auto;
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: 500px; /* Adjust this height as needed */
  margin-bottom: 20px;
`;

export default Research1;
