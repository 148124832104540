import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const projectData = [
  {
    id: 1,
    date: 2023,
    title: 'PAX - GENDER',
    degree: 'PhD',
    people: 'Jinrui Wang, Tomas Vancisin, Laura Wise, Xinhuan Shu, Tara Capel, Uta Hinrichs',
    medium: 'Data physicalization'
  },
  {
    id: 2,
    date: 2023,
    title: 'A Spring Ahead',
    degree: 'Design Informatics',
    people: 'Jiamei Huang, Michaella Yosephine',
    medium: 'Data physicalization'
  },
  {
    id: 3,
    date: 2023,
    title: 'Physicalization Gendered Data',
    degree: 'Design Informatics',
    people: '',
    medium: 'Workshop'
  },
  // Add more projects as needed
];

const Projects = () => {
  return (
    <Section>
      <Heading>Projects</Heading>
      {projectData.map(project => (
        <ProjectRow key={project.id}>
          <Date>{project.date}</Date>
          <Title>
            <Link to={`/project/${project.id}`}>{project.title}</Link>
          </Title>
          <Degree>{project.degree}</Degree>
          <People>{project.people}</People>
          <Medium>{project.medium}</Medium>
        </ProjectRow>
      ))}
    </Section>
  );
};

const Section = styled.section`
  padding: 20px;
  margin-top: 20px; /* Add space above the projects section */
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
  font-family: 'Arimo', sans-serif;
`;

const ProjectRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #00b8ff;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
`;

const Date = styled.span`
  flex: 1;
  color: #00b8ff;
  font-family: 'Arimo', sans-serif;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Title = styled.span`
  flex: 2;
  a {
    color: #00b8ff;
    text-decoration: none;
    font-family: 'Arimo', sans-serif;
    font-size: 24px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Degree = styled.span`
  flex: 1;
  color: #00b8ff;
  font-family: 'Arimo', sans-serif;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const People = styled.span`
  flex: 2;
  color: #00b8ff;
  font-family: 'Arimo', sans-serif;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Medium = styled.span`
  flex: 1;
  color: #00b8ff;
  font-family: 'Arimo', sans-serif;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export default Projects;
