import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import Header from './components/Header';
import Projects from './components/Projects';
import Research from './components/Research';
import Project1 from './pages/Project1';
import Project2 from './pages/Project2';
import Project3 from './pages/Project3';
import Research1 from './pages/Research1';
import Research2 from './pages/Research2';
import Research3 from './pages/Research3';
import Introduction from './components/Introduction';
import ProjectIntroduction from './components/ProjectIntroduction';
import ResearchIntroduction from './components/ResearchIntroduction';
import Experience from './components/Experience';
import Footer from './components/Footer';

const App = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const renderIntroduction = () => {
    if (location.pathname.startsWith('/project/')) {
      return <ProjectIntroduction />;
    } else if (location.pathname.startsWith('/research/')) {
      return <ResearchIntroduction />;
    } else {
      return <Introduction />;
    }
  };

  return (
    <>
      <GlobalStyles />
      <Header />
      <MainContainer className={`${isHomePage ? 'home-page' : ''} ${isHomePage ? '' : 'page-container'}`}>
        {renderIntroduction()}
        <Routes>
          <Route path="/" element={
            <>
              <Projects />
              <Research />
              <Experience />
            </>
          } />
          <Route path="/project/1" element={<Project1 />} />
          <Route path="/project/2" element={<Project2 />} />
          <Route path="/project/3" element={<Project3 />} />
          <Route path="/research/1" element={<Research1 />} />
          <Route path="/research/2" element={<Research2 />} />
          <Route path="/research/3" element={<Research3 />} />
        </Routes>
      </MainContainer>
      <Footer />
    </>
  );
};

const MainContainer = styled.div`
  width: 100%;
`;

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
