import React from 'react';
import styled from 'styled-components';

const ProjectIntroduction = () => {
  return (
    <IntroContainer>
      <p>same for all</p>
    </IntroContainer>
  );
};

const IntroContainer = styled.div`
  padding: 20px;
  margin: 0 auto; /* Center the container */
  max-width: 600px; /* Limit the width of the container */
  text-align: left;
  background: linear-gradient(to middle, #00fff9, #f5f5f5); /* Gradient background */
  color: #333; /* Ensure the text color matches the theme */
`;

export default ProjectIntroduction;
