import React from 'react';
import styled from 'styled-components';

const Header = () => {
  return (
    <HeaderContainer>
      <LeftSection>
        <h2>Jenny Long</h2>
      </LeftSection>
      <RightSection>
        <p><a href="mailto:jennylzx@outlook.com">jennylzx@outlook.com</a></p>
      </RightSection>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  background-color: #f5f5f5; /* Switch to white background */
  color: #00fff9; /* Switch text color to match original background */
  padding: 5px 20px; /* Reduce height of the header */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00fff9; /* Add border for better separation */
`;

const LeftSection = styled.div`
  h2 {
    margin: 0;
    font-size: 20px; /* Slightly bigger text */
    color: #00fff9; /* Ensure the text color matches the original background */
  }
`;

const RightSection = styled.div`
  p {
    margin: 0;
    font-size: 15px; /* Slightly smaller text for the email */
  }
  a {
    color: #00fff9; /* Ensure the email color matches the original background */
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default Header;
