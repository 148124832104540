import React from 'react';
import styled from 'styled-components';
import sah1Image from '../assets/SAH1.jpg';
import sah2Image from '../assets/SAH2.jpg';
import sah3Image from '../assets/SAH3.jpg';
import sah4Image from '../assets/SAH4.jpg';
import sah5Image from '../assets/SAH5.jpg';
import sah6Image from '../assets/SAH6.jpg';
import sah7GIF from '../assets/SHA7.gif';


const Project2 = () => {
  return (
    <ProjectContainer>
      <Heading>A Spring Ahead</Heading>
      <IntroParagraph>
      A Spring Ahead, a project by Jenny Long, Jiamei Huang and Michaella Yosephine. Winner of the CDCS Digital Research Prize: Best Small Data-Driven Project
Human activities have propagated climate change, causing earlier ecological spring and misbalancing interspecies interactions. One example is the tritrophic (plant-herbivore-predator) interactions between trees’ first leaf, caterpillar peak population, and bird peak feeding demand. These events occur cyclically, also known as “phenology”.
Warmer temperatures lead to earlier spring phenologies. Some creatures, like birds, adapt slower. This forms mismatches between peak resource demand (birds feeding) and availability (caterpillar population). Eventually, this can cause population decline.
“A Spring Ahead” simulates how rising temperatures affect UK’s spring tritrophic phenologies based on data from Nature's Calendar, Met Office, and research by Burgess et al. (2018). Each phenology is portrayed as small acrylic pieces. Overlapping pieces depict matching phenologies. Over the pieces, lies a transparent disc with a quarter gap that shows ecological spring duration. Surrounding the pieces is a fixed, circular 365-day calendar, meant to show the rigidity of our concept of time.
Visitors can turn a knob to change the spring temperature, which ranges from 8 degrees to 13 degrees. The temperature will change the position of each petal. The higher the temperature, the larger the gap between the petals, showing increasing phenological mismatches. As the temperature increases, the ecological spring plate will show spring starting earlier, portraying the flexibility of ecological time.
      </IntroParagraph>

      <h2>Data Analysis </h2>
      <IntroParagraph>
      Here are three visualisations inspired by the dataset we studied. You can get an in-depth look into the forecasting code by clicking on the 'view code' button.
      </IntroParagraph>
      <Section>
        <EmbeddedVisualization>
          <iframe 
            src="https://flo.uri.sh/story/1874026/embed" 
            className="flourish-embed-iframe" 
            frameBorder="0" 
            scrolling="no" 
            style={{ width: '1000px', height: '600px' }} 
            title="Embedded Visualization"
          ></iframe>
        </EmbeddedVisualization>
      </Section>

      <h2>Design Process </h2>
      <IntroParagraph>
        Sketches and prototypes are made during the ideation, design, and construction processes.
      </IntroParagraph>
      <Section>
        <Gif src= {sah7GIF}  alt="Design Ecologies Animation" />
      </Section>

      <Section>
        <ImagesContainer>
          <Image src={sah1Image} alt="Design Ecologies Image 1" />
          <Image src={sah2Image} alt="Design Ecologies Image 2" />
          <Image src={sah3Image} alt="Design Ecologies Image 3" />
          <Image src={sah4Image} alt="Design Ecologies Image 4" />
          <Image src={sah5Image} alt="Design Ecologies Image 5" />
          <Image src={sah6Image} alt="Design Ecologies Image 6" />
        </ImagesContainer>
      </Section>

      <h2>Video</h2>
      <IntroParagraph>
      This video explains more about the background concept and how the installation works. 
      </IntroParagraph>
      <Section>
        <VideoFrame 
          src="https://player.vimeo.com/video/822681754?h=a01a30709a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
          frameBorder="0" 
          allow="autoplay; picture-in-picture" 
          allowFullScreen
          title="Vimeo Video"
        ></VideoFrame>
      </Section>
    </ProjectContainer>
  );
};

const ProjectContainer = styled.div`
  padding: 20px;
  font-family: 'Segoe UI', 'Arial', sans-serif;
`;

const Heading = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
`;

const IntroParagraph = styled.p`
  font-size: 1rem;
  color: #333;
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-bottom: 20px;
  padding: 30px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmbeddedVisualization = styled.div`
  margin-bottom: 20px;
`;

const Gif = styled.img`
  max-width: 100%;
  height: auto;
`;

const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr)); /* Adjust minmax values as needed */
  gap: 10px;
  margin-bottom: 30px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the grid item */
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: 500px; /* Adjust this height as needed */
  margin-bottom: 20px;
`;

export default Project2;
