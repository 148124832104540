import React from 'react';
import styled from 'styled-components';

const Project1 = () => {
  return (
    <ProjectContainer>
      <h1>PAX - GENDER</h1>
      <p>Details about the PAX - GENDER project...</p>
    </ProjectContainer>
  );
};

const ProjectContainer = styled.div`
  padding: 20px;
`;

export default Project1;
