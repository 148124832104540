import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Footer = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <FooterContainer isHomePage={isHomePage}>
      Jenny Long © 2024
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  max-width: 100%;
  text-align: left;
  padding: 10px 20px;
  font-family: 'Arimo', sans-serif;
  background: ${({ isHomePage }) => (isHomePage ? '#00fff9' : '#ffffff')};
  color: ${({ isHomePage }) => (isHomePage ? 'white' : 'black')};
`;

export default Footer;
