import React from 'react';
import styled from 'styled-components';

const Research2 = () => {
  return (
    <ResearchContainer>
      <h1>Research 2</h1>
      <p>Details about the second research topic...</p>
    </ResearchContainer>
  );
};

const ResearchContainer = styled.div`
  padding: 20px;
`;

export default Research2;
