import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Segoe UI', 'Arial', sans-serif;
    background: #ffffff; /* Default background color */
    color: #333;
    min-height: 100vh;
    overflow-y: scroll;
    position: relative;
    width: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .page-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .home-page {
    background: linear-gradient(to top, #00fff9, #f5f5f5); /* Gradient background for home page */
  }
`;

export default GlobalStyles;
